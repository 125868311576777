import { Document } from '@contentful/rich-text-types';
import { globalObservability } from '@opendoor/observability/slim';
import { Entry } from 'contentful';

import { fetchArticlesByTags, fetchEntriesById } from 'cms/api';

import Container from 'components/landing-pages-v2/shared/Container';
import {
  getComponentThemeColors,
  IComponentThemeOptions,
} from 'components/landing-pages-v2/shared/styles/ComponentTheme';
import CardCarouselV2 from 'components/shared/CardCarouselV2';
import MulticardCard, { IMulticardCardDetails } from 'components/shared/Multicard/MulticardCard';
import MulticardList from 'components/shared/Multicard/MulticardList';

import { Awaited, EntryComponent } from '../../cms/entries/entries';
import {
  IArticle,
  IArticleFields,
  IAuthorFields,
  IComponentRelatedArticles,
  IEditorFields,
  ILpComponentMulticard,
  IReviewerFields,
} from '../../declarations/contentful';
import { getSkipOffset } from '../../helpers/pagination';
import { CardSectionHeader } from './CardSectionHeader';

export const ARTICLES_PER_PAGE = 16;

type IAuthorLite = Pick<
  IAuthorFields,
  'displayName' | 'credential' | 'picture' | 'firstName' | 'lastName'
>;

export type IArticleLite = Pick<
  IArticleFields,
  | 'slug'
  | 'articleName'
  | 'headerSummary'
  | 'authors'
  | 'editors'
  | 'reviewers'
  | 'openGraph'
  | 'publishDate'
  | 'articleSubtitle'
>;

export const maparticlesToGenericMulticardDetails = (
  article: IArticleFields | IArticleLite,
  theme: IComponentThemeOptions,
  limit?: number,
): IMulticardCardDetails => {
  return {
    title: article?.articleName,
    imageUrl:
      article?.openGraph?.fields?.image ||
      'https://images.opendoor.com/source/s3/imgdrop-production/4b831192b06849a589afcef62a25757d.jpg?preset=square-2048',
    componentTheme: theme,
    redirectUrl: article?.slug,
    desc: article?.articleSubtitle || '',
    cardsLimit: limit,
  };
};

const renderArticlesCards = (
  entry: IComponentRelatedArticles,
  resolvedData?: Awaited<ReturnType<typeof articlesLoader>>,
) => {
  const DEFAULT_CAROUSEL_CARDS_LIMIT = 4;
  let articles: Array<IArticle | Entry<IArticleLite>> =
    entry.fields?.items ?? resolvedData?.articles ?? [];
  articles = articles.filter((a) => !!a.fields);
  let limit = !entry.fields?.limit ? DEFAULT_CAROUSEL_CARDS_LIMIT : entry.fields?.limit;
  limit = articles.length < limit ? articles.length : limit;
  const componentTheme = getComponentThemeColors(entry.fields.cardBackgroundColor || 'Off White');
  if (articles.length === 0) {
    return null;
  }
  return (
    <Container paddingTop="$32x">
      <CardSectionHeader
        titleSize={entry.fields.titleSize}
        title={entry.fields.title}
        titleAccent={entry.fields.titleAccent}
        desc={entry.fields.desc}
        eyebrow={entry.fields.eyebrow}
        subhead={entry.fields.subHead}
      />
      {entry.fields.display === 'list' ? (
        <MulticardList
          cards={articles.map((article) =>
            maparticlesToGenericMulticardDetails(article.fields, componentTheme),
          )}
          columnNumber={4}
          componentTheme={componentTheme}
          cardVerticalAlignment=""
        />
      ) : (
        <CardCarouselV2
          id={entry.sys.id}
          cards={articles}
          componentTheme={componentTheme}
          columnNumber={limit}
          renderCard={(card, id) => (
            <MulticardCard
              id={id}
              card={maparticlesToGenericMulticardDetails(card.fields, componentTheme, limit)}
              componentTheme={componentTheme}
              cardVerticalAlignment="Top"
            />
          )}
        />
      )}
    </Container>
  );
};

// Fetch authors, editors, and reviewers if componentExperts is also in root
const fetchExperts = async (
  articles: (IArticle | Entry<IArticleLite>)[],
  root?: Entry<any>,
): Promise<Array<IAuthorFields | IEditorFields | IReviewerFields>> => {
  // check if componentExperts exists in root
  let isComponentExpertsFound = false;
  for (const content of (root?.fields.body.content ?? []) as Document[]) {
    if (content.data?.target?.sys?.contentType?.sys?.id === 'componentExperts') {
      isComponentExpertsFound = true;
      break;
    }
  }
  if (!isComponentExpertsFound) {
    return [];
  }
  const experts: (IAuthorFields | IEditorFields | IReviewerFields)[] = [];

  // fetch authors
  const authorIds: string[] = [];
  for (const article of articles) {
    for (const author of article.fields?.authors ?? []) {
      authorIds.push(author.sys.id);
    }
  }
  const authorEntries = await fetchEntriesById<IAuthorFields>('author', authorIds);
  experts.push(...authorEntries.map((author) => author?.fields ?? null));

  // fetch editors
  const editorIds: string[] = [];
  for (const article of articles) {
    for (const editor of article.fields.editors ?? []) {
      editorIds.push(editor.sys.id);
    }
  }
  const editorEntries = await fetchEntriesById<IEditorFields>('editor', editorIds);
  experts.push(...editorEntries.map((editor) => editor?.fields ?? null));

  // fetch reviewers
  const reviewerIds: string[] = [];
  for (const article of articles) {
    for (const reviewer of article.fields.reviewers ?? []) {
      reviewerIds.push(reviewer.sys.id);
    }
  }
  const reviewerEntries = await fetchEntriesById<IReviewerFields>('reviewer', reviewerIds);
  experts.push(
    ...reviewerEntries.reduce<Array<IReviewerFields>>((acc, reviewer) => {
      if (!reviewer?.fields) {
        return acc;
      }
      return acc.concat(reviewer.fields);
    }, []),
  );

  return experts;
};

export type IArticlesLoaderReturn = {
  authors: Array<IAuthorFields>;
  articles: Array<Entry<IArticleLite>>;
  experts: Array<IAuthorFields | IEditorFields | IReviewerFields>;
  tagNamesLookup: Array<{ [key: string]: string }>;
  total: number | undefined;
};

export const articleLiteFields =
  'fields.slug,fields.articleName,fields.publishDate,metadata.tags,fields.openGraph';
const articleRichFields = 'fields.headerSummary,fields.authors,fields.editors,fields.reviewers';

export const articlesLoader = async (
  input: IComponentRelatedArticles | ILpComponentMulticard,
  root?: Entry<any>,
  pageProps = { paginationIndex: 0 },
) => {
  const loaderReturn: IArticlesLoaderReturn = {
    authors: [],
    articles: [],
    experts: [],
    tagNamesLookup: [],
    total: 0,
  };

  let articles: Array<IArticle | Entry<IArticleLite>> = [];
  if ('items' in input.fields) {
    articles = input.fields?.items || [];
  }
  let tags: string[] = [];
  // Auto selection if no articles is provided
  if (articles.length === 0) {
    tags = input?.metadata?.tags
      ?.filter((item) => item?.sys?.type === 'Link' && item?.sys?.linkType === 'Tag')
      ?.map((item) => item?.sys?.id);
    const articlesCollection = await fetchArticlesByTags(tags || root?.fields.topics, {
      select: `${articleLiteFields},${articleRichFields}`,
      skip: getSkipOffset(pageProps.paginationIndex),
      limit: input.fields?.limit || ARTICLES_PER_PAGE,
    });

    articles = articlesCollection?.items || [];
    const totalArticles = articlesCollection?.total;

    loaderReturn.total = totalArticles;
    loaderReturn.articles = articles;
  }

  // check output for errors
  const missingFields = loaderReturn.articles.some((article) => !article.fields);
  if (missingFields) {
    const sentry = globalObservability.getSentryClient();
    if (sentry !== undefined) {
      sentry.withScope?.((scope) => {
        scope.setContext('Loader Variables', {
          pageProps,
          tags,
        });
        sentry.captureMessage?.('articlesLoader returned undefined articles.fields', 'error');
      });
    }
    // fall back to logging to the console just in case
    // eslint-disable-next-line no-console
    console.log('articlesLoader returned undefined articles.fields', 'error', {
      data: {
        input,
        tags,
        root,
        pageProps,
        articles: loaderReturn.articles,
      },
    });
  }

  // Fetch author of articles
  const authorIds = articles.map((a) => a.fields?.authors?.[0].sys.id ?? '');
  const authorEntries = await fetchEntriesById<IAuthorLite>('author', authorIds, {
    select: 'fields.displayName,fields.credential,fields.picture,fields.firstName,fields.lastName',
  });
  loaderReturn.authors = authorEntries.map((author) => author?.fields ?? null); // JSON serializer cannot accept undefined value

  loaderReturn.experts = await fetchExperts(articles, root);

  return loaderReturn;
};

const RelatedArticles: EntryComponent<
  IComponentRelatedArticles,
  Awaited<ReturnType<typeof articlesLoader>>
> = {
  render: renderArticlesCards,
  loader: articlesLoader,
};

export default RelatedArticles;
