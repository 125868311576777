/* storybook-check-ignore */
import { Box, Text, TextProps } from '@opendoor/bricks-next';
import 'swiper/css';
import 'swiper/css/pagination';

import { ILpComponentMulticardFields } from 'declarations/contentful';

import { Desc, Eyebrow, Subhead, SubheadSmall } from '../landing-pages-v2/shared/Typography';
import { markdownToReactElement } from '../landing-pages-v2/shared/utils';

type TextTokenProps = Omit<TextProps, 'tag' | 'typography'> & {
  children?: string;
  size?: Exclude<TextProps['typography'], number | undefined>;
};

interface TitleWithAccentProps extends TextTokenProps {
  title?: string;
  titleAccent?: string;
  titleSize?: any;
  titleColor?: TextProps['color'];
  titleAccentColor?: TextProps['color'];
  breakTitles?: boolean;
}

const TitleWithAccent = ({
  title,
  titleAccent,
  titleSize,
  titleColor,
  titleAccentColor,
  breakTitles,
  ...props
}: TitleWithAccentProps) => {
  const typography = {
    typography:
      titleSize === 'Large' ? '$headerLarge' : ('$headerMedium' as TextProps['typography']),
    $largerThanSM: {
      typography: titleSize === 'Large' ? '$displaySmall' : '$headerMedium',
    } as TextProps['$largerThanSM'],
    $largerThanMD: {
      typography: titleSize === 'Large' ? '$displaySmall' : '$headerMedium',
    } as TextProps['$largerThanMD'],
  };

  return (
    <Text
      tag="h2"
      {...typography}
      display="block"
      maxWidth={'16ch' as unknown as number}
      color={titleColor || '$contentPrimary'}
      mb={'$8x'}
      {...props}
      whiteSpace="normal"
    >
      {markdownToReactElement(title || '')}
      {breakTitles && <br />}
      {titleAccent && (
        <Text
          tag="span"
          {...typography}
          color={titleAccentColor || '$contentTertiary'}
          {...props}
          whiteSpace="normal"
        >
          {' '}
          {markdownToReactElement(titleAccent)}
        </Text>
      )}
    </Text>
  );
};

type CardSectionHeaderProps = Pick<
  ILpComponentMulticardFields,
  'eyebrow' | 'title' | 'subhead' | 'desc' | 'titleAccent' | 'titleSize'
>;

export const CardSectionHeader = (fields: CardSectionHeaderProps) => {
  return (
    <Box gap={'$16x'} marginBottom={'$16x'} maxWidth={'$744x'}>
      {fields.eyebrow && <Eyebrow>{fields.eyebrow}</Eyebrow>}
      {fields.title && (
        <TitleWithAccent
          title={fields.title}
          titleAccent={fields.titleAccent}
          titleSize={fields.titleSize}
        />
      )}
      {(fields.subhead || fields.desc) && (
        <Box gap="$4x">
          {fields.subhead &&
            (fields.titleSize === 'Large' ? (
              <Subhead color="$contentPrimary">{fields.subhead}</Subhead>
            ) : (
              <SubheadSmall color="$contentPrimary">{fields.subhead}</SubheadSmall>
            ))}
          {fields.desc && (
            <Desc
              color="$contentPrimary"
              $largerThanMD={{ fontSize: fields.titleSize === 'Large' ? 24 : 'unset' }}
            >
              {fields.desc}
            </Desc>
          )}
        </Box>
      )}
    </Box>
  );
};
